import React ,{useEffect}from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./Home.scss";

import { useGetMoviesQuery, useGetShowsQuery } from "../../../redux/show/shows";
import { incrementHomePage, decrementHomePage, setHomePage,  } from "../../../redux/show/pageTracker";

import MovieGrid from "../../MovieGrid/MovieGrid";
import ShowGrid from "../../ShowGrid/ShowGrid";

function Home() {
  const { type } = useParams();
  
  // dispatch setHomePage
  const dispatch = useDispatch()
  dispatch(setHomePage(type));
  
  const showType= useSelector((state) => state.pageTracker.current);
  const currentType = useSelector((state) => state.pageTracker.currentType);
  const currentGenre = useSelector((state) => state.pageTracker.movies.genres.genre);

  return (
    <div>
      <div className="heading-primary">Home</div>
      <h2>{type}</h2>
      {showType === "m" ? (
        <MovieGrid type={currentType} genre={currentGenre} />
      ) : (
        <ShowGrid type={currentType} genre={currentGenre} />
      )}
    </div>
  );
}

export default Home;
