// slice
import { createSlice } from "@reduxjs/toolkit";

import {urls} from '../../api/tmdb';

const { getMovies, searchMovies } = urls;

const initialState = {
    current: "m",
    currentType: "popular",
    movies: {
        home: {
            page: 1,
        },
        search: {
            page: 1,
            query: "",
        },
        popular: {
            page: 1,
        },
        topRated: {
            page: 1,
        },
        upcoming: {
            page: 1,
        },
        genres: {
            page: 1,
            genre: "",
        },
    },
    shows: {
        home: {
            page: 1,
        },
        search: {
            page: 1,
            query: "",
        },
        popular: {
            page: 1,
        },
        topRated: {
            page: 1,
        },
        upcoming: {
            page: 1,
        },
        genres: {
            page: 1,
            genre: "",
        },
    },
};

const showSlice = createSlice({
    name: 'showPage',
    initialState,
    reducers: {
        incrementHomePage: (state, action) => {
            if (action.payload === "movies") state.movies.home.page += 1;
            else state.shows.home.page += 1;
        },
        decrementHomePage: (state, action) => {
            if (action.payload === "movies"){
                if (state.movies.home.page > 1)
                state.movies.home.page -= 1;
            }
            else {
                if (state.shows.home.page > 1)
                state.shows.home.page -= 1;
            }
        },
        setHomePage: (state, action) => {
            // if (action.payload.type === "movies") state.movies.home.page = action.payload.page;
            // else state.shows.home.page = action.payload.page;
            switch (action.payload) {
                case "popular":
                    state.currentType = "popular"
                    break;
                case "upcoming":
                    state.currentType = "upcoming";
                    break;
                case "topRated":
                    state.currentType = "topRated"
                    break;
                default:
                    if (!action.payload ) break;
                    // split the string to get the type and the id
                    const [type, id] = action.payload.split("-")
                    if(type === "genres"){
                        state.currentType = "genres"
                        state.shows.genres.genre = id;
                        state.movies.genres.genre = id;
                    }

                    break;
            }
        },
        changeCurrent: (state, action) => {
            state.current = action.payload;
        }
    }

})

export const { incrementHomePage, decrementHomePage, setHomePage, changeCurrent } = showSlice.actions;
export default showSlice.reducer;