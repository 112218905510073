import React from 'react';
import {
  Outlet,
} from "react-router-dom";

import Bar from "../../Bar/Bar";
import SideNav from "../../SideNav/SideNav";


import './Main.scss';

const Main = () => {
  return (
    <main>
      <div className="container">
          <div className="container__left">
              <SideNav />
          </div>
          <div className="container__right">
            <Bar />
            <div>
              <Outlet />
            </div>
          </div>
      </div>
    </main>
  )
}

export default Main