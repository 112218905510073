import React, { useState, useRef } from "react";
import {  FiSearch } from "react-icons/fi";
import {Link} from "react-router-dom";

import { searchMovies } from "../../api/tmdb";
import { imgPath } from "../../api/tmdb";

import "./AutoComplete.scss";

const Bar = (props) => {
    const [resultHTML, setResultHTML] = useState("");
    const [classList, setClassList] = useState("");
    const [inputValue, setInputValue] = useState("");
    const [debounceId, setDebounceId] = useState(null);

    const inputRef = useRef(null);

    function handleItemClick(e, title) {
        setInputValue(title);
        setClassList("");
        inputRef.current.focus();
    }

    const onData = props.onData || function (e, data) {
        console.log(data);
    };

    function onSearch (e) {
        if (e.type === "keydown" && e.key !== "Enter") {
            return;
        }

        const value = inputValue;
        setClassList("");
        searchMovies(value).then((data) => {
            onData(e, data);
        });
    };


    
    async function handleSearch(e) {
        setInputValue(e.target.value);
        clearTimeout(debounceId);
        let id = setTimeout(async () => {
            const result = await searchMovies(e.target.value);

            // limit result count to 10
            result.results = result.results.slice(0, 10);

            let resultHTML = result.results.map((item) => (
                <Link to={`/m/${item.id}`} className="auto-com__result-item" key={ Math.random() + item.title }>
                <li 
                    className="auto-com__result-item" 
                    onClick={
                        ()=>{
                            handleItemClick(this, item.title)
                        }
                    } 
                    key={ Math.random() + item.title }
                >
                    <img className="auto-com__result-img" src={imgPath + item.poster_path} />
                    <span className="auto-com__result-text">{item.title}</span>
                </li>
                </Link>
            ));

			if(resultHTML.length){
				setClassList("active-input");
			} else {
				setClassList("");
			}
            setResultHTML(resultHTML);
            console.log("feched")
        }, 800);
        setDebounceId(id);
    }

    return (
        <div className={`auto-com ${classList}`} onKeyDown={onSearch}>
            <div className="auto-com__input-holder">
                <input 
                    className="auto-com__input" 
                    onInput={handleSearch} 
                    type="text" 
                    placeholder="Search" 
                    value={inputValue} 
                    ref={inputRef}
                />
                <div className="auto-com__icon-holder">
                    <FiSearch className="auto-com__icon" onClick={onSearch} />
                </div>
            </div>
            <div className="auto-com__result-trey">
                <ul className="auto-com__result-list">{resultHTML}</ul>
            </div>
        </div>
    );
};

export default Bar;
