import React from "react";
import {Link} from "react-router-dom";

import "../../scss/_variables.scss"
import "./MovieCard.scss";

const {urls} = require('../../api/tmdb');

function MovieCard(props) {
    const { movie } = props;
    return (
      <div className="movie-card">
        <Link to={`/m/${movie.id}`} className="movie-card__link">
          <div className="movie-card__img">
            <img
              src={urls.tmdbImageUrl(movie.poster_path)}
              alt="movie poster"
            />
          </div>
          <div className="movie-card__info">
            <div className="movie-card__info__title">
              <h3>{movie.title}</h3>
            </div>
            <div className="movie-card__info__release-date">
              <span>{movie.release_date}</span>
            </div>
            <div className="movie-card__info__rating">
              <span>⭐</span>
              <span>{movie.vote_average}</span>
              <span>({movie.vote_count})</span>
            </div>
          </div>
        </Link>
      </div>
    );
}

export default MovieCard;
