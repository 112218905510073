import React from 'react';
import { useDispatch } from 'react-redux';

import {
  changeCurrent
} from '../../redux/show/pageTracker';

import AutoComplete from '../AutoComplete/AutoComplete';

import './Bar.scss';

const Bar = () => {
  const dispatch = useDispatch();

  const handleTypeChange = (e) => {
    if(e.target.value === "movies") {
      dispatch(changeCurrent("m"));
    } else {
      dispatch(changeCurrent("t"));
    }
  }
  return (
    <div className="top-bar">
        <div className="top-bar__container">
            <div className="top-bar__type" >
                  {/* dropdown to select movies or tv */}
                  <select className="top-bar__type__dropdown" onChange={handleTypeChange}>
                      <option value="movies">Movies</option>
                      <option value="tv">TV</option>
                  </select>
            </div>
            <AutoComplete />
        </div>
    </div>
  )
}



export default Bar