import React from "react";
import { useDispatch, useSelector } from "react-redux";

import "./ShowGrid.scss";

import ShowCard from "../ShowCard/ShowCard";

import { incrementHomePage, decrementHomePage, setHomePage } from "../../redux/show/pageTracker";
import { useGetShowsQuery, useGetShowGenresQuery } from "../../redux/show/shows";


function ShowGrid({ type,  genre}) {
    if(!type) type = "popular"
    const dispatch = useDispatch();

    const page = useSelector((state) => state.pageTracker.shows.home.page);
    const currentType = useSelector((state) => state.pageTracker.currentType);

    const { data, error, isLoading } = useGetShowsQuery({
        page, 
        limit: 40, 
        type:currentType,
        with_genres: currentType === "genres" ? [genre] : [],
    });
    console.log(data);

    const handleNextPage = () => {
        dispatch(incrementHomePage("shows"));
    };

    const handlePrevPage = () => {
        dispatch(decrementHomePage("shows"));
    };

    const handleSetPage = (e) => {
        dispatch(setHomePage(e.target.value));
    };
    return (
        <>
            {isLoading && <div>Loading...</div>}
            {error && <div>{JSON.stringify(error)}</div>}
            <div>
                <div className="show-card-setup">
                    <div className="show-card-container">{data && data.results.map((show) => <ShowCard key={show.id} show={show} />)}</div>
                    <div className="show-card-controls ">
                        <button className="btn--secondary" onClick={handlePrevPage}>
                            Prev
                        </button>
                        {<span>{String(page)}</span>}
                        <button className="btn--secondary" onClick={handleNextPage}>
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ShowGrid;
