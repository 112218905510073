const baseUrl = "https://2embed.me";

export const urls = {
    getIFrame: (showType,id,season = 1,episode = 1) => {
        if (showType === "m") {
            return `${baseUrl}/movie/${id}`;
        } else {
            return `${baseUrl}/tv/${id}/S${season}/E${episode}`;
        }
    },
};

