import React from "react";
import {
    useDispatch,
    useSelector
} from "react-redux";
import {
    Link
} from "react-router-dom";

import {
    useGetMovieGenresQuery,
    useGetShowGenresQuery
} from "../../redux/show/shows";

import "./SideNav.scss";
import "../../scss/typography.scss";
import "../../scss/util.scss";


import logo from "../../images/logo.svg";

const SideNav = () => {

    const dispatch = useDispatch();

    const current = useSelector((state) => state.pageTracker.current);

    let genres = {
        data: [],
        error: "",
        isLoading: true
    };

    const {
        data: movieGenres,
        error: movieGenresError,
        isLoading: movieGenresLoading
    } = useGetMovieGenresQuery();

    const {
        data: showGenres,
        error: showGenresError,
        isLoading: showGenresLoading
    } = useGetShowGenresQuery();

    if(current === "m") {
        genres.data = movieGenres;
        genres.error = movieGenresError;
        genres.isLoading = movieGenresLoading;
    }
    else if(current === "t"){
        genres.data = showGenres;
        genres.error = showGenresError;
        genres.isLoading = showGenresLoading;
    };

    setTimeout(() => {
        console.log(genres);
    }, 2000);


    return (
        <div className="side-nav">
            <div className="side-nav__item side-nav__logo-box .u-margin-bottom-small ">
                <img src={logo} alt="logo" className="side-nav__logo" />
            </div>

            <div className="side-nav__item side-nav__cat ">
				<h2 className="side-nav__cat-title heading-secondary u-margin-bottom-small u-margin-top-small ">
					Categories
				</h2>
                <ul className="side-nav__cat-list">
                    <li className="side-nav__cat-item">
                        <Link to="/popular" className="side-nav__cat-link">
                            Papular
                        </Link>
                    </li>
                    <li className="side-nav__cat-item">
                        <Link to="/topRated" className="side-nav__cat-link">
                            Top Rated
                        </Link>
                    </li>
                    <li className="side-nav__cat-item">
                        <Link to="/upcoming" className="side-nav__cat-link">
                            Upcoming
                        </Link>
                    </li>
                </ul>
            </div>

            <div className="side-nav__item side-nav__genres">
				<h2 className="side-nav__genres-title heading-secondary u-margin-top-small">Genres</h2>
                <ul className="side-nav__genres-list">
                    { !genres.isLoading && genres.data && genres.data.genres.map((genre) => {
                        return (
                            <li
                                key={`${genre.id}`} className="side-nav__genres-item"
                                >
                                {/* <a href="#" value = {genre.id} className="side-nav__genres-link">
                                    {genre.name}
                                </a> */}
                                <Link to={`/genres-${genre.id}`} value = {genre.id} className="side-nav__genres-link">
                                    {genre.name}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default SideNav;
