import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "./App.scss";

import Home  from "./components/layout/Home/Home";
import Main from "./components/layout/Main/Main";
import ShowDeep from "./components/ShowDeep/ShowDeep";


const router = createBrowserRouter([
    // {
    //     path: "/",
    //     element: <Main />,
    // },
    {
        path: "/",
        element: <Main />,
        children: [
          {
            path: "",
            element: <Home />,
          },
          {
            path: "/:type",
            // path: ":type",
            element: <Home />,
          },
          {
            path: "/:showType/:id",
            element: <ShowDeep />,
          },
        ],
    },
]);


function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
