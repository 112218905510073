import React from "react";
import {Link} from "react-router-dom";

import "../../scss/_variables.scss"
import "./ShowCard.scss";

const {urls} = require('../../api/tmdb');

function ShowCard(props) {
    const { show } = props;
    return (
      <div className="show-card">
        <Link to={`/t/${show.id}`} className="show-card__link">
          <div className="show-card__img">
            <img src={urls.tmdbImageUrl(show.poster_path)} alt="show poster" />
          </div>
          <div className="show-card__info">
            <div className="show-card__info__title">
              <h3>{show.name}</h3>
            </div>
            <div className="show-card__info__release-date">
              <span>{show.first_air_date}</span>
            </div>
            <div className="show-card__info__rating">
              <span>⭐</span>
              <span>{show.vote_average}</span>
              <span>({show.vote_count})</span>
            </div>
          </div>
        </Link>
      </div>
    );
}

export default ShowCard;
