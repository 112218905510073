// redux rtx api slice
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import {urls} from '../../api/tmdb';

const { getMovies, getShows , searchMovies, movieGenres, showGenres } = urls;

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({ baseUrl: "https://api.themoviedb.org/3" }),
  endpoints: (builder) => ({
    getMovies: builder.query({
      query: ({ page = 1, limit = 40, type, with_genres = [] }) => {
        return getMovies(page, limit, type, with_genres);
      },
    }),
    getShows: builder.query({
      query: ({page = 1, limit = 40, type, with_genres = []}) =>
        getShows(page, limit, type, with_genres = []),
    }),
    searchMovies: builder.query({
      query: ({ query, page }) => searchMovies(query, page),
    }),
    getMovieGenres: builder.query({
      query: () => movieGenres,
    }),
    getShowGenres: builder.query({
      query: () => showGenres,
    }),
  }),
});



export const { 
    useGetMoviesQuery, 
    useSearchMoviesQuery, 
    useGetShowsQuery,
    useGetMovieGenresQuery,
    useGetShowGenresQuery,
} = apiSlice;
export default apiSlice.reducer;
