const apiKey = process.env.API_KEY || "15d2ea6d0dc1d476efbca3eba2b9bbfb";
const baseUrl = "https://api.themoviedb.org/3";

export const imgPath = "https://image.tmdb.org/t/p/w500";
const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
export const urls = {
  getMovies: (page = 1, limit = 20, type = "popular", with_genres = []) => {
    let typeUrl = `movie/${camelToSnakeCase(type)}`;

    if (with_genres.length > 0) {
      typeUrl = `discover/movie`;
    }
    return `${baseUrl}/${typeUrl}?api_key=${apiKey}&language=en-US&page=${page}&limit=${limit}&sort_by=popularity.desc${
      with_genres.length > 0 ? `&with_genres=${with_genres.join(",")}` : ""
    }`;
  },
  getShows: (page = 1, limit = 20, type = "popular", with_genres = []) => {
    let typeUrl = `tv/${camelToSnakeCase(type)}`;

    if (with_genres.lenth > 0) {
      typeUrl = `discover/tv`;
    }
    return `${baseUrl}/${typeUrl}?api_key=${apiKey}&language=en-US&page=${page}&limit=${limit}&sort_by=popularity.desc${
      with_genres.length > 0 ? `&with_genres=${with_genres.join(",")}` : ""
    }`;
  },
  searchMovies: (query, page) =>
    `${baseUrl}/search/movie?api_key=${apiKey}&language=en-US&query=${query}&page=${page}`,
  tmdbImageUrl: (path) => `${imgPath}/${path}`,
  movieGenres: `${baseUrl}/genre/movie/list?api_key=${apiKey}&language=en-US`,
  showGenres: `${baseUrl}/genre/tv/list?api_key=${apiKey}&language=en-US`,
  getMovieDetails: (id) => `${baseUrl}/movie/${id}?api_key=${apiKey}&language=en-US`,
  getShowDetails: (id) => `${baseUrl}/tv/${id}?api_key=${apiKey}&language=en-US`,
  getSeasons: (id, seasonNumber) => `${baseUrl}/tv/${id}/season/${seasonNumber}?api_key=${apiKey}&language=en-US`,
};

export const getMovies = async (page = 1) => {
    const response = await fetch(urls.getMovies(page));
    const data = await response.json();
    return data;
}

export const searchMovies = async (query, page = 1) => {
    const response = await fetch(urls.searchMovies(query, page));
    const data = await response.json();
    return data;
}


export const getMovieDetails = async (id) => {
    const response = await fetch(urls.getMovieDetails(id));
    const data = await response.json();
    console.log(data);
    return data;
}

export const getShowDetails = async (id) => {
    const response = await fetch(urls.getShowDetails(id));
    const data = await response.json();
    console.log(data);
    return data;
}


export const getSeasonDetail = async (id, seasonNumber) => {
    const response = await fetch(urls.getSeasons(id, seasonNumber));
    const data = await response.json();
    console.log(data);
    return data;
}
