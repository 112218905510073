import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./MovieGrid.scss";

import MovieCard from "../MovieCard/MovieCard";

import { incrementHomePage, decrementHomePage, setHomePage } from "../../redux/show/pageTracker";
import { useGetMoviesQuery } from "../../redux/show/shows";


function MovieGrid({ type, genre  }) {
    if(!type) type = "popular"

    const dispatch = useDispatch();
    const page = useSelector((state) => state.pageTracker.movies.home.page);
    const currentType = useSelector((state) => state.pageTracker.currentType);


    const { data, error, isLoading } = useGetMoviesQuery({
      page,
      limit: 40,
      type: currentType,
      with_genres: currentType === "genres" ? [genre] : [],
    });

    // console.log(data);

    const handleNextPage = () => {
        dispatch(incrementHomePage("movies"));
    };

    const handlePrevPage = () => {
        dispatch(decrementHomePage("movies"));
    };

    const handleSetPage = (e) => {
        dispatch(setHomePage(e.target.value));
    };
    return (
      <>
        {isLoading && <div>Loading...</div>}
        {error && <div>{JSON.stringify(error)}</div>}
        {!error && (
          <div>
            <div className="movie-card-setup">
              <div className="movie-card-container">
                {data &&
                  data.results.map((movie) => (
                    <MovieCard key={movie.id} movie={movie} />
                  ))}
              </div>
              <div className="movie-card-controls ">
                <button className="btn--secondary" onClick={handlePrevPage}>
                  Prev
                </button>
                {<span>{String(page)}</span>}
                <button className="btn--secondary" onClick={handleNextPage}>
                  Next
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
}

export default MovieGrid;
