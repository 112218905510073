import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

import { Button} from "@mui/material";

import "./ShowDeep.scss";

import {
  getMovieDetails,
  getShowDetails,
  getSeasonDetail,
} from "../../api/tmdb";
import { Rating } from "@mui/material";

import {
    urls
} from "../../api/sec";

const {
    getIFrame
} = urls;


function ShowDeep() {
  const { showType, id } = useParams();

  const [showDetails, setShowDetails] = useState({});
  const [seasons, setSeasons] = useState([]);
  const [episodes, setEpisodes] = useState([]);
  const [episode, setEpisode] = useState(1);
  const [seasonNumber, setSeasonNumber] = useState(1);

  useEffect(() => {

    if (showType === "m") {
        getMovieDetails(id).then((data) => {
        console.log(data);
        setShowDetails(data);
        });
    } else if (showType === "t") {
        getShowDetails(id).then((data) => {
        console.log(data);
        setShowDetails(data);

        const seasons = data.seasons;
        setSeasons(seasons)

        setSeason(seasons[0]?.season_number);

        getSeasonDetail(id, seasons[0].season_number).then((data) => {
            console.log(data);
            setEpisodes(data.episodes);
            setEpisode(data.episodes[0]?.episode_number);
        });
    });
    }
  }, []);

  async function setSeason(seasonNumber) {
    setSeasonNumber(seasonNumber)
    const season = await getSeasonDetail(id, seasonNumber);
    setEpisodes(season.episodes);
  }

  function handleSeasonChange(e) {
    setSeason(e.target.value);
  }

  function handleEpisodeChange(e) {
    setEpisode(e.target.value);
  }



  return (
    <>
      <div className="show-deep">
        <div className="show-deep__back">
          <Link to="/" className="show-deep__back__link">
            <span>⬅</span>
            <span>Back</span>
          </Link>
        </div>
        <div className="show-deep__img">
          <img
            src={`https://image.tmdb.org/t/p/w500${showDetails.poster_path}`}
            alt="show poster"
          />
        </div>
        <div className="show-deep__info">
          <div className="show-deep__info__title">
            <h3>
              {showType === "m"
                ? showDetails.title || showDetails.original_title
                : showDetails.name || showDetails.original_name}
            </h3>
          </div>
          <div className="show-deep__info__release-date">
            <span>
              {showType === "m"
                ? showDetails.release_date
                : `${showDetails.first_air_date}/${showDetails.last_air_date}`}
            </span>
          </div>
          <div className="show-deep__info__rating">
            <span>⭐</span>
            <span>{showDetails.vote_average}</span>
            <Rating
              name="read-only"
              value={showDetails.vote_average / 2}
              readOnly
            />
            <span>({showDetails.vote_count})</span>
          </div>
          <div className="show-deep__info__overview">
            <p>{showDetails.overview}</p>
          </div>

          <div className="show-deep__info__genres">
            <span>Genres: </span>
            {showDetails.genres?.map((genre) => (
              <span>{genre.name}, </span>
            ))}
          </div>

          <div className="show-deep__info__budget">
            <span>Budget: </span>
            <span>{showDetails.budget}</span>
          </div>

          <div className="show-deep__info__production-companies">
            <span>Production Companies: </span>
            {showDetails.production_companies?.map((company) => (
              <span>{company.name}, </span>
            ))}
          </div>

          <div className="show-deep__info__production-countries">
            <span>Production Countries: </span>
            {showDetails.production_countries?.map((country) => (
              <span>{country.name}, </span>
            ))}
          </div>

          <div className="show-deep__info__spoken-languages">
            <span>Spoken Languages: </span>
            {showDetails.spoken_languages?.map((language) => (
              <span>{language.name}, </span>
            ))}
          </div>

          <div className="show-deep__info__status">
            <span>Status: </span>
            <span>{showDetails.status}</span>
          </div>

          <div className="show-deep__info__tagline">
            <span>Tagline: </span>
            <span>{showDetails.tagline}</span>
          </div>
        </div>
      </div>

      {showType === "m" ? (
        <iframe
          title={showDetails.id}
          src={getIFrame(showType, showDetails.imdb_id)}
          allowFullScreen = {true}
        ></iframe>
      ) : (
        <iframe
         title={showDetails.id}
         src={getIFrame(showType, showDetails.id, seasonNumber, episode)}
            allowFullScreen = {true}    
        ></iframe>
      )}

      {showType === "t" ? (
        <div className="show-deep__seasons">
          <select name="seasons" id="seasons" onChange={handleSeasonChange}>
            {seasons.map((season) => (
              <option value={season.season_number}>{season.name}</option>
            ))}
          </select>
        </div>
      ) : (
        ""
      )}

      {showType === "t" ? (
        <div className="show-deep__episodes">
          {episode}
          {episodes.map((episode) => (
            <Button
              variant="contained"
              value={episode.episode_number}
              onClick={handleEpisodeChange}
            >
              {episode.episode_number}
            </Button>
          ))}
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default ShowDeep;
