// redux toolkit store
import { configureStore } from '@reduxjs/toolkit';

import {apiSlice} from './show/shows';
import pageTracker from './show/pageTracker';

const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        pageTracker: pageTracker,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(apiSlice.middleware),
});

export default store;
